import {NgModule} from "@angular/core";

import {
    TrackingFacade
} from "./services";
import {
    WalletFacade
} from "./services";

import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {TrackingRequest} from "./requests/tracking.request";
import {WalletRequest} from "./requests/wallet.request";

@NgModule({
    providers: [
        WalletFacade,
        WalletRequest,
        TrackingRequest,
        TrackingFacade
    ],
    imports: [ReactiveFormsModule, CommonModule],


})
export class CommonStelvioModule {}
