import {Component, Input, OnInit} from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-product-body',
  templateUrl: './product-body.component.html',
  styleUrls: ['./product-body.component.scss'],
})
export class ProductBodyComponent implements OnInit {
  public segmentSelected: string = "segment1"
  @Input('steps') steps: any;
  @Input('product') product: any;
  @Input('user') user: any;
  @Input('workflowId') workflowId: any;
  @Input('workflowName') workflowName: any;
  @Input('workflowDescription') workflowDescription: any;


  @Input('productData') productData: any;

  constructor(private navController:NavController) { }

  ngOnInit() {
    
  }
  productSegmentChanged(ev: any) {
    event.preventDefault();
    event.stopPropagation();
    this.segmentSelected = ev.detail.value
  }
  showTrackingMapPage(){
    this.navController.navigateForward("/tracking-map/" + this.workflowId)
  }
}
