import {Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {GoogleMap, Marker} from '@capacitor/google-maps';
import {TrackingFacade} from "../../../../../../common/services";
import {environment} from "../../../../../../../environments/environment";

import {take} from "rxjs";
import {HttpEventType} from "@angular/common/http";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {FileOpener} from "@awesome-cordova-plugins/file-opener/ngx";
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'stelvio-product-details-modal',
    templateUrl: './product-details-modal.component.html',
    styleUrls: ['./product-details-modal.component.scss']
})
export class ProductDetailsModalComponent implements OnInit {
    @Input() item: any;
    fields: any;
    documents: any;
    images: any;
    facility: any;
    image: any;
    document: any;
    map: GoogleMap;
    downloadProgress = 0;

    stepLayout: any = 1;

    @ViewChild('details') detailsTmp: TemplateRef<any>;
    @ViewChild('location') locationTmp: TemplateRef<any>;
    @ViewChild('certificates') certificatesTmp: TemplateRef<any>;
    @ViewChild('map') mapRef: ElementRef<HTMLElement>;

    options: any = {
        disableDefaultUI: true,
        zoom: 5
    };

    activeTemplate: TemplateRef<any>;
    activeBtn: string = 'details';

    constructor(private trackingFacade: TrackingFacade,
                private modalCtrl: ModalController,
                private fileOpener: FileOpener,
                private sanitizer: DomSanitizer) {

    }

    async ngOnInit() {
        this.activeTemplate = this.detailsTmp;
        this.fields = this.item.fields;
        this.documents = this.item.documents;
        this.images = this.item.images;

        this.facility = this.item.facility;
        await new Promise(resolve => setTimeout(resolve, 200));
        this.loadPartial(this.detailsTmp, "details");
        this.stepLayout = this.item.companyMediaLayout
    }

    downloadImage(path: string, companyId: string, name: string) {
        const imagePath: string = `/images/${companyId}/${path}`;
        const url = imagePath;
        const link = document.createElement('a');
        link.href = url;
        link.download = `${name}`;
        link.click();
        URL.revokeObjectURL(url);
    }

    private convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader;
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    })

    private getMimeType(name: string) {
        if (name.indexOf('pdf') >= 0) {
            return 'application/pdf';
        } else if (name.indexOf('png') >= 0) {
            return 'image/png';
        } else if (name.indexOf('jpg') >= 0) {
            return 'image/jpg';
        }
    }

    download(url: string) {
        this.trackingFacade.download(url).subscribe(async event => {
            if (event.type === HttpEventType.DownloadProgress) {
                this.downloadProgress = Math.round((100 * event.loaded) / event.total);
            } else if (event.type === HttpEventType.Response) {
                this.downloadProgress = 0;

                const name = url.substr(url.lastIndexOf('/') + 1);
                const base64 = await this.convertBlobToBase64(event.body) as string;

                const savedFile = await Filesystem.writeFile({
                    path: name,
                    data: base64,
                    directory: Directory.Documents
                })

                const path = savedFile.uri;
                console.log(name);
                const mimeType = this.getMimeType(name);
                this.fileOpener.open(path, mimeType)
            }
        })
    }

    downloadDocument(path: string, companyId: string, name: string) {
        const documentPath: string = `/documents/${companyId}/${path}`;
        const url = documentPath;
        const link = document.createElement('a');
        link.href = url;
        link.download = `${name}`;
        link.click();
        URL.revokeObjectURL(url);
    }

    async createMap() {
        await new Promise(resolve => setTimeout(resolve, 900));
        const marker = this.getMarker();

        this.map = await GoogleMap.create({
            id: 'my-cool-map',
            element: this.mapRef.nativeElement,
            apiKey: environment.apiKey,
            config: {
                center: {
                    lat: marker.coordinate.lat,
                    lng: marker.coordinate.lng,
                },
                zoom: 8,
            },
        });
        await this.map.addMarker(marker);
    }

    getMarker(): Marker {
        const lati = parseFloat(this.facility.latitude);
        const longi = parseFloat(this.facility.longitude);

        return {
            coordinate: {
                lat: lati,
                lng: longi
            },
            title: this.facility.name,
            snippet: this.facility.address
        }
    }


    async loadPartial(partial: TemplateRef<any>, btnActive: string) {
        this.activeBtn = btnActive;
        this.activeTemplate = partial;
        if (btnActive == 'location') {
            await this.createMap();
        }
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

    getImageCompany() {
        if (this.item.companyImage && this.item.companyImage.path != '') {
            return `/images/${this.item.companyId}/${this.item.companyImage.path}`
        } else {
            return ''
        }
    }

    getTextCompany() {
        if (this.item.companyImage.length > 0 && this.item.companyImage.text != '' && this.item.companyImage.text != 'null') {
            return `${this.item.companyImage.text}`
        } else {
            return ''
        }
    }

    getVideoCompany() {
        if (this.item.companyImage && this.item.companyImage.videoLink != '' && this.item.companyImage.videoLink != 'null') {
            return this.setVideoLink(this.item.companyImage.videoLink)
        } else {
            return ''
        }
    }

    setVideoLink(link: string) {
        let videoEmbedUrl = '';
        const videoUrl = link;
        const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/(watch\?v=|embed\/|v\/)?([a-zA-Z0-9_-]{11})/;
        const vimeoRegex = /^(https?\:\/\/)?(www\.)?(vimeo)\.com\/(\d+)($|\/)/;
        const dailymotionRegex = /^(https?\:\/\/)?(www\.)?(dailymotion)\.com\/video\/(\w+)(.*)/;
        const directVideoRegex = /\.(mp4|ogg|webm)$/i;
        const normalRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\??([\w.-]+=[\w.-]+&?)*$/;

        if (normalRegex.test(videoUrl)) {
            if (videoUrl.match(youtubeRegex)) {
                const youtubeMatch = videoUrl.match(youtubeRegex);
                if (youtubeMatch) {
                    videoEmbedUrl = `https://www.youtube.com/embed/${youtubeMatch[6]}`;
                }
            } else if (videoUrl.match(vimeoRegex)) {
                videoEmbedUrl = videoUrl.replace(vimeoRegex, 'https://player.vimeo.com/video/$4');
            } else if (videoUrl.match(dailymotionRegex)) {
                videoEmbedUrl = videoUrl.replace(dailymotionRegex, 'https://www.dailymotion.com/embed/video/$4');
            } else if (videoUrl.match(directVideoRegex)) {
                videoEmbedUrl = videoUrl;
            }
        }
        return videoEmbedUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(videoEmbedUrl) : videoEmbedUrl
    }
}
