import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";

import { IonicModule } from "@ionic/angular";
import { CommonStelvioModule } from "../../../../common/common.module";
import { ProductRatingsPortesaPage } from "./pages/product-ratings/product-ratings.page";
import { PortesaProductTemplateComponent } from "./pages/portesa-product-template/portesa-product-template.component";
import { ProductHeaderComponent } from "./pages/portesa-product-template/includes/product-header/product-header.component";
import { ProductBodyComponent } from "./pages/portesa-product-template/includes/product-body/product-body.component";
import { ProductHistoryComponent } from "./pages/portesa-product-template/includes/product-history/product-history.component";
import { ProductTimelineComponent } from "./pages/portesa-product-template/includes/product-timeline/product-timeline.component";
import { ProductTimelineItemComponent } from "./pages/portesa-product-template/includes/product-timeline-item/product-timeline-item.component";
import { ProductDetailsComponent } from "src/app/templates/portesa/pages/products/pages/portesa-product-template/includes/product-details/product-details.component";
import { ProductNewsletterComponent } from "src/app/templates/portesa/pages/products/pages/portesa-product-template/includes/product-newsletter/product-newsletter.component";
import { ProductRelatedComponent } from "src/app/templates/portesa/pages/products/pages/portesa-product-template/includes/product-related/product-related.component";
import { ProductRatingsComponent } from "src/app/templates/portesa/pages/products/pages/portesa-product-template/includes/product-ratings/product-ratings.component";
import { ProductRatingItemComponent } from "./components/product-rating-item/product-rating-item.component";
import { ProductDetailsModalComponent } from "./components/product-details-modal/product-details-modal.component"; 
import { ProductTrackingMapComponent } from "./pages/portesa-product-template/includes/product-tracking-map/product-tracking-map.component"; 
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CommonStelvioModule,
  ],
  declarations: [
    PortesaProductTemplateComponent,
    ProductHeaderComponent,
    ProductBodyComponent,
    ProductHistoryComponent,
    ProductTimelineComponent,
    ProductTimelineItemComponent,
    ProductTimelineComponent,
    ProductDetailsComponent,
    ProductNewsletterComponent,
    ProductRelatedComponent,
    ProductRatingsComponent,
    ProductRatingItemComponent,
    ProductRatingsPortesaPage,
    ProductDetailsModalComponent,
    ProductTrackingMapComponent
  ],
  exports: [ 
    PortesaProductTemplateComponent,
    ProductHeaderComponent,
    ProductBodyComponent,
    ProductHistoryComponent,
    ProductTimelineComponent,
    ProductTimelineItemComponent,
    ProductTimelineComponent,
    ProductDetailsComponent,
    ProductNewsletterComponent,
    ProductRelatedComponent,
    ProductRatingsComponent,
    ProductRatingItemComponent,
    ProductRatingsPortesaPage,
    ProductDetailsModalComponent,
    ProductTrackingMapComponent]

})
export class ProductsModulePortesa {}
