export const environment = {
  production: true,
  apiURL: 'https://dev.stelviotech.com/api',
    apiKey: 'AIzaSyCQDujsqA0yn_q4wr04eRAu2qC6GyAFeRQ',
  auth: {
    apiURL: 'https://dev.stelviotech.com',
    clientSecret: '0pCfKIoCo7H1aqb9WhZSOMngbLvAcWWo',
    grantType: 'password',
    clientId: 'bbtwins-api',
    refreshGrantType: 'refresh_token',
  },
  pagination: {
    pageSize: 20
  },
  appVersion: 'v8.0.38',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'api',
  appThemeName: 'Metronic',
  appPurchaseUrl: 'https://1.envato.market/EA4JP',
  appHTMLIntegration: 'https://preview.keenthemes.com/metronic8/demo6/documentation/base/helpers/flex-layouts.html',
  appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo6/',
  appPreviewAngularUrl: 'https://preview.keenthemes.com/metronic8/angular/demo6',
  appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
  appPreviewChangelogUrl: 'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
};
