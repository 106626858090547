import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { IonAccordionGroup, ModalController } from "@ionic/angular";
import { ProductDetailsModalComponent } from "../../../../components/product-details-modal/product-details-modal.component";
import { GoogleMap, Marker } from "@capacitor/google-maps";
import {environment} from "../../../../../../../../../environments/environment";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { HttpEventType } from "@angular/common/http";
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: "app-product-timeline-item",
  templateUrl: "./product-timeline-item.component.html",
  styleUrls: ["./product-timeline-item.component.scss"],
})
export class ProductTimelineItemComponent implements OnInit {
  @Input("steps") steps: any;
  @Input("product") product: any;
  @ViewChild("accordionGroup") accordionGroup: any;
  @ViewChildren('mapContainer') mapContainers: QueryList<ElementRef>;
  mapContainersArray: ElementRef[];

  map: GoogleMap;

  stepImage: string;
  companyImage: string = "";
  companyName: string;
  constructor(private modalCtrl: ModalController,private sanitizer: DomSanitizer) {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.mapContainersArray = this.mapContainers.toArray();

  }
  async createMap(step,index) {
    await new Promise((resolve) => setTimeout(resolve, 900));
    const marker = this.getMarker(step);

    this.map = await GoogleMap.create({
      id: "step-map" + step.id,
      element: this.mapContainersArray[index].nativeElement,
      apiKey: environment.apiKey,
      config: {
        center: {
          lat: marker.coordinate.lat,
          lng: marker.coordinate.lng,
        },
        zoom: 15,
      },
    });
    await this.map.addMarker(marker);
  }


  getMarker(step): Marker {
    const lati = parseFloat(step.facility.latitude);
    const longi = parseFloat(step.facility.longitude);

    return {
        coordinate: {
            lat: lati,
            lng: longi
        },
        title: step.facility.name,
        snippet: step.facility.address
    }
}
  toggleAccordion = (index, step, event) => {
    //this.showDetails(step)

    this.createMap(step,index)
    const seeMoreBtnTitle = event.target.querySelector(".see-more-text")
      ? event.target.querySelector(".see-more-text")
      : event.target;
    const nativeEl = document.getElementById("stepMoreDetails" + index);
    if (nativeEl.classList.contains("hide")) {
      nativeEl.classList.remove("hide");
      seeMoreBtnTitle.innerText = "See less";
    } else {
      nativeEl.classList.add("hide");

      seeMoreBtnTitle.innerText = "See more";
    }
  };

  getStepImage(path: string) {
    this.companyImage = `/images/${this.product.company.id}/${this.product.company.logo?.path}`;
    this.companyName = this.product.company.name;
    if (path) {
      this.stepImage = `/images/${this.product.company.id}/${path}`;
    } else {
      this.stepImage = this.companyImage;
    }
    return this.stepImage;
  }

  async showDetails(item: any) {
    const modal = await this.modalCtrl.create({
      component: ProductDetailsModalComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 0.7,
      handle: false,
      componentProps: { item },
    });

    await modal.present();
  }

  downloadImage(path: string, companyId: string, name: string) {
    const imagePath: string = `/images/${companyId}/${path}`;
    const url = imagePath;
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name}`;
    link.click();
    URL.revokeObjectURL(url);
}



downloadDocument(path: string, companyId: string, name: string) {
    const documentPath: string = `/documents/${companyId}/${path}`;
    const url = documentPath;
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name}`;
    link.click();
    URL.revokeObjectURL(url);
}

getImageCompany(step) {
  if (step.companyImage && step.companyImage.path != '') {
      return `/images/${step.companyId}/${step.companyImage.path}`
  } else {
      return ''
  }
}

getTextCompany(step) {
  if (step.companyImage.length > 0 && step.companyImage.text != '' && step.companyImage.text != 'null') {
      return `${step.companyImage.text}`
  } else {
      return ''
  }
}

getVideoCompany(step) {
  if (step.companyImage && step.companyImage.videoLink != '' && step.companyImage.videoLink != 'null') {
      return this.setVideoLink(step.companyImage.videoLink)
  } else {
      return ''
  }
}

setVideoLink(link: string) {
  let videoEmbedUrl = '';
  const videoUrl = link;
  const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/(watch\?v=|embed\/|v\/)?([a-zA-Z0-9_-]{11})/;
  const vimeoRegex = /^(https?\:\/\/)?(www\.)?(vimeo)\.com\/(\d+)($|\/)/;
  const dailymotionRegex = /^(https?\:\/\/)?(www\.)?(dailymotion)\.com\/video\/(\w+)(.*)/;
  const directVideoRegex = /\.(mp4|ogg|webm)$/i;
  const normalRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\??([\w.-]+=[\w.-]+&?)*$/;

  if (normalRegex.test(videoUrl)) {
      if (videoUrl.match(youtubeRegex)) {
          const youtubeMatch = videoUrl.match(youtubeRegex);
          if (youtubeMatch) {
              videoEmbedUrl = `https://www.youtube.com/embed/${youtubeMatch[6]}`;
          }
      } else if (videoUrl.match(vimeoRegex)) {
          videoEmbedUrl = videoUrl.replace(vimeoRegex, 'https://player.vimeo.com/video/$4');
      } else if (videoUrl.match(dailymotionRegex)) {
          videoEmbedUrl = videoUrl.replace(dailymotionRegex, 'https://www.dailymotion.com/embed/video/$4');
      } else if (videoUrl.match(directVideoRegex)) {
          videoEmbedUrl = videoUrl;
      }
  }
  return videoEmbedUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(videoEmbedUrl) : videoEmbedUrl
}
}
