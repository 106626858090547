import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-header',
  templateUrl: './product-header.component.html',
  styleUrls: ['./product-header.component.scss'],
})
export class ProductHeaderComponent implements OnInit {

  @Input("imagePath") imagePath:any;
  constructor() { }

  ngOnInit() {}


}
