import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { TemplateConfig } from "./templates/template.config";

@Injectable({
  providedIn: "root",
})
export class TemplateService {
  public template;
  constructor() {
  }

  loadTemplate(selectedTemplate) {
    this.template = selectedTemplate
    return selectedTemplate?.name &&
      TemplateConfig.templates[selectedTemplate.name]
      ? TemplateConfig.templates[selectedTemplate.name]
      : "stelvio";
  }

  getTemplate(){
    return this.template;
  }
}
