import {Component, OnInit} from '@angular/core';
import {SplashScreen} from "@capacitor/splash-screen";
import {NavController, Platform} from "@ionic/angular";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    currentRoute: string;
    showTitle: boolean;
    public appPages = [
        {title: 'Home', url: '/main', icon: 'home'},
        {title: 'Search', url: '/search', icon: 'search'},
        {title: 'Product Details', url: '/folder/Favorites', icon: 'heart'},
        {title: 'Settings', url: '/folder/Archived', icon: 'archive'},
    ];
    mostrarTitulo: boolean = true;
    pageTitle: string = '';


    constructor(private platform: Platform,
                private router: Router, private route: ActivatedRoute,private navCtrl: NavController) {
        this.showTitle = true;
        this.currentRoute = '';

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.urlAfterRedirects.split('/')[1]; // Obtén el segmento de ruta después del dominio
                this.showTitle = this.currentRoute === 'main' ? this.route.snapshot.firstChild.data.showTitle : true;
            }
        });
    }
    onMostrarTituloChanged(valor: boolean): void {
        this.showTitle = valor;
    }
    onSetPageTitle(valor: string): void {
        this.pageTitle = valor;
    }
    goHome(){
         this.router.navigate(['/']);

    }
    ngOnInit() {

        this.platform.ready().then(async () => {
            SplashScreen.hide();
        });
    }
}
