import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit {
  @Input("product") product: any;
  @Input("workflowDescription") workflowDescription:string;

  companyLogo: string;
  constructor(private router: Router) {}

  ngOnInit() {
    if(this.product&&this.product.company){
      this.companyLogo = `/images/${this.product.company.id}/${this.product.company.logo?.path}`
    }
  }

  navigateToRatingsPage() {
    this.router.navigate(["products/ratings"]);
  }
  goToRRSS(url:string){
    window.open(url, "_blank");
  }
}
