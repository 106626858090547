import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-product-body',
  templateUrl: './product-body.component.html',
  styleUrls: ['./product-body.component.scss'],
})
export class ProductBodyComponent implements OnInit {
  public segmentSelected: string = "segment1"
  @Input('steps') steps: any;
  @Input('product') product: any;
  @Input('user') user: any;
  @Input('workflowId') workflowId: any;
  @Input('workflowName') workflowName: any;
  @Input('workflowDescription') workflowDescription: any;

  constructor() { }

  ngOnInit() {
  }
  productSegmentChanged(ev: any) {
    this.segmentSelected = ev.detail.value
  }
}
