import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { CommonStelvioModule } from "../../../../common/common.module";
import { ProductTemplateComponent } from "./pages/product-template/product-template.component";
import { ProductRatingsDimitraPage } from "./pages/product-ratings/product-ratings.page";
import { ProductHeaderComponent } from "./pages/product-template/includes/product-header/product-header.component";
import { ProductBodyComponent } from "./pages/product-template/includes/product-body/product-body.component";
import { ProductHistoryComponent } from "./pages/product-template/includes/product-history/product-history.component";
import { ProductTimelineComponent } from "./pages/product-template/includes/product-timeline/product-timeline.component";
import { ProductTimelineItemComponent } from "./pages/product-template/includes/product-timeline-item/product-timeline-item.component";
import { ProductDetailsComponent } from "./pages/product-template/includes/product-details/product-details.component";
import { ProductNewsletterComponent } from "./pages/product-template/includes/product-newsletter/product-newsletter.component";
import { ProductRelatedComponent } from "./pages/product-template/includes/product-related/product-related.component";
import { ProductRatingsComponent } from "./pages/product-template/includes/product-ratings/product-ratings.component";
import { ProductRatingItemComponent } from "./components/product-rating-item/product-rating-item.component"; 
import { ProductDetailsModalComponent } from "./components/product-details-modal/product-details-modal.component"; 
import { ProductTrackingMapComponent } from "./pages/product-template/includes/product-tracking-map/product-tracking-map.component"; 

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CommonStelvioModule,
  ],
  declarations: [
    ProductTemplateComponent,
    ProductHeaderComponent,
    ProductBodyComponent,
    ProductHistoryComponent,
    ProductTimelineComponent,
    ProductTimelineItemComponent,
    ProductTimelineComponent,
    ProductDetailsComponent,
    ProductNewsletterComponent,
    ProductRelatedComponent,
    ProductRatingsComponent,
    ProductRatingItemComponent,
    ProductRatingsDimitraPage,
    ProductDetailsModalComponent,
    ProductTrackingMapComponent
  ],
  exports: [
    ProductTemplateComponent,
    ProductHeaderComponent,
    ProductBodyComponent,
    ProductHistoryComponent,
    ProductTimelineComponent,
    ProductTimelineItemComponent,
    ProductTimelineComponent,
    ProductDetailsComponent,
    ProductNewsletterComponent,
    ProductRelatedComponent,
    ProductRatingsComponent,
    ProductRatingItemComponent,
    ProductRatingsDimitraPage,
    ProductDetailsModalComponent,
    ProductTrackingMapComponent
  ],
})
export class ProductsModuleDimitra {}
