import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class DomainConfigService {

  constructor() { }

  static getSubDomain(): string {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    switch ((subdomain)) {
      case "portesa":
        return subdomain;
        break;
      case "dimitra":
        return subdomain;
        break;
      default:
       return 'stelvio'
    }
  }
}
