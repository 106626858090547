import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InfiniteScrollCustomEvent } from '@ionic/angular';

@Component({
  selector: 'app-product-ratings-portesa-page',
  templateUrl: './product-ratings.page.html',
  styleUrls: ['./product-ratings.page.scss'],
})
export class ProductRatingsPortesaPage implements OnInit {
  count:number = 5;
  items = Array(this.count).fill(1).map((x,i)=>i);
  constructor(private activeRoute: ActivatedRoute
    ) { }

  ngOnInit() {
    const id = this.activeRoute.snapshot.params['id'];
    this.generateItems();

    }

    private generateItems() {
      this.count+=5
      this.items = Array(this.count).fill(1).map((x,i)=>i);
    
    }
    numSequence(n: number): Array<number> { 
      return Array(n); 
    } 
    onIonInfinite(ev) {
      this.generateItems();
      setTimeout(() => {
        (ev as InfiniteScrollCustomEvent).target.complete();
      }, 500);
    }
}
