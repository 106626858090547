import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-rating-item',
  templateUrl: './product-rating-item.component.html',
  styleUrls: ['./product-rating-item.component.scss'],
})
export class ProductRatingItemComponent implements OnInit {

  @Input('isRatingPage') isRatingPage:boolean;
  constructor() { }

  ngOnInit() {
    console.log(this.isRatingPage)
  }

}
