import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {TrackingSearchFiltersModel} from "../../models/batch-tracking/TrackingSearchFiltersModel";
import {Observable, of} from "rxjs";


@Injectable()
export class TrackingRequest {
    private apiRoot: string = `${environment.apiURL}/public/tracking/`;

    constructor(private http: HttpClient) {
    }
    query(filters?: TrackingSearchFiltersModel): Observable<any> {
        return this.http.get(`${this.apiRoot}`, {
            params: {
                ...filters
            }
        });
    }

    query2(filters?: TrackingSearchFiltersModel): Observable<any> {
        return this.http.get(`${this.apiRoot}`, {
            params: {
                ...filters
            }
        });
    }


    getById(trackingId: string) {
        // @ts-ignore
        //return of(trackingItem.default);
        return this.http.get(`${this.apiRoot}${trackingId}/`);
    }

    getByQr(ean: string, batchId: string) {
        // @ts-ignore
        //return of(trackingItem.default);
        return this.http.get(`${this.apiRoot}qrcode/${ean}/${batchId}`);
    }

    getImage(id: string) {
        return this.http.get(`${this.apiRoot}image/${id}/`);
    }

    getDocument(id: string) {
        return this.http.get(`${this.apiRoot}document/${id}/`);
    }


    download(url: string) {
        return this.http.get(url, {
            responseType: 'blob',
            reportProgress: true,
            observe: 'events'
        });
    }
}
