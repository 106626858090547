import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {TrackingSearchFiltersModel} from "../../models/batch-tracking/TrackingSearchFiltersModel";
import {Observable, of} from "rxjs";


@Injectable()
export class WalletRequest {
    private apiRoot: string = `${environment.apiURL}`;

    constructor(private http: HttpClient) {
    }

    query(filters?: TrackingSearchFiltersModel): Observable<any> {
        return this.http.get(`${this.apiRoot}`, {
            params: {
                ...filters
            }
        });
    }

    getUserBalance(userId: number, tokenId: string) {
        return this.http.get(`${this.apiRoot}/public/balance/${userId}/${tokenId}`);
    }

    postVote(userId: number, tokenId: string,projectId: number,amount:number) {

        return this.http.post(`${this.apiRoot}/vote/`, {
            userId:userId,
            tokenId:tokenId,
            projectId:projectId,
            amount:amount
        });
    }

    addUserBalance(userId: number, balance: number,tokenId:string) {
        return this.http.post(`${this.apiRoot}/public/balance/add`, {userId, balance,tokenId});
    }

    getActivities() {
        return this.http.get(`${this.apiRoot}/sustainability/activities/6` );
    }
    getActivityDetails(activity: number){
        return this.http.get(`${this.apiRoot}/sustainability/activity/${activity}`);
    }
    getLastVotes(userId: string){
        return this.http.get(`${this.apiRoot}/vote/${userId}`);
    }
    getLastLogs(userId: string){
        return this.http.get(`${this.apiRoot}/public/balance/logs/${userId}`);
    }
}
