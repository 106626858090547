import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {WalletRequest} from "../requests/wallet.request";

@Injectable()
export class WalletFacade {
    constructor(private request: WalletRequest) {
    }

    query(): Observable<any> {

        return this.request.query();
    }

    getUserBalance(params: any) {
        console.log(params)
        return this.request.getUserBalance(params.userId,params.tokenId);
    }

    postVote(params: any){
        return this.request.postVote(params.userId,params.tokenId,params.projectId,params.amount);

    }
    addUserBalance(params: any) {
        return this.request.addUserBalance(params.userId,params.balance,params.tokenId);
    }

    getActivityDetails(activity:number){
        return this.request.getActivityDetails(activity);

    }
    getActivities(){
        return this.request.getActivities();

    }
    getLastVotes(userId:string){
        return this.request.getLastVotes(userId);

    }  getLastLogs(userId:string){
        return this.request.getLastLogs(userId);

    }
}
