import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-product-timeline',
    templateUrl: 'product-timeline.component.html',
    styleUrls: ['./product-timeline.component.scss'],

})
export class ProductTimelineComponent {
    @Input('steps') steps: any;
    @Input('product') product: any;

    constructor() {

    }

}
