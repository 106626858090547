import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit {
  @Input("workflowDescription") workflowDescription: string;
  @Input("product") product: any;
  @Input("productData") productData: any

  productExtras: any;
  productHighlights:any[] = [];

  productBanner:any;
  productList:any = [];
  productVideos:any = [];
  productProvider:any = [];

  constructor(private router: Router,public sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.handleProductExtraData()
  }

  navigateToRatingsPage() {
    this.router.navigate(["products/ratings"]);
  }

  goToRRSS(url: string) {
    window.open(url, "_blank");
  }
  handleProductExtraData() {
    console.log(this.productData)
    this.productExtras = this.productData.extras
    this.productExtras.elements.map((extra:any)=>{
      if(extra.type == "highlight"){
        this.productHighlights.push(extra);
      }
      if(extra.type == "banner"){
        this.productBanner = extra;
      }
      if(extra.type == "list"){
        this.productList.push(extra);
      }
      if(extra.type == "video"){
        this.productVideos.push(extra);
      }
      if(extra.type == "provider"){
        this.productProvider = extra;
      }
    })
    console.log(this.productExtras.elements)
  }

  sinitizerVideoUrl(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url) 
  }

  formateVideoURL(link:string){
    const url = this.sanitizer.bypassSecurityTrustResourceUrl(link)
    return url
}
}
