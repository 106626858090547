import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-product-template',
    templateUrl: './product-template.component.html',
    styleUrls: ['./product-template.component.scss'],
})
export class ProductTemplateComponent implements OnInit {
    //TODO:
    @Input('steps') steps: any;
    @Input('product') product: any;
    @Input('user') user: any;
    @Input('imagePath') imagePath: any;
    @Input('workflowId') workflowId: any;
    @Input('workflowName') workflowName: any;
    @Input('workflowDescription') workflowDescription: any;



    constructor() {
    }

    ngOnInit() {
    }



}
