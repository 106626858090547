import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TemplateService } from "../../template.service";
import { TrackingFacade } from 'src/app/common/services';
export interface Template {
  id: number;
  name: string;
  description?: string;
}

@Component({
  selector: 'app-product-ratings-page',
  templateUrl: './product-ratings.page.html',
  styleUrls: ['./product-ratings.page.scss'],
})
export class ProductRatingsPage implements OnInit {

  params: any = {};
  workflowInstance: any;
  product: any;
  user: any;
  steps: any[] = [];
  imagePath: string;
  defaultPic: string = "../../../../../assets/img/generic-product.webp";

  workflowTemplate: Template;
  productTemplate: Template;
  companyTemplate: Template;
  selectedTemplate: Template;
  activedTemplate:string;

  constructor(private templateService: TemplateService,private trackingFacade:TrackingFacade,    private route: ActivatedRoute,

    ) { }

  ngOnInit() {

    console.log(this.templateService.getTemplate())
    const id = this.route.snapshot.params["id"];
    const ean = this.route.snapshot.queryParams["ean"];
    const batchId = this.route.snapshot.queryParams["batchId"];

    if (ean && batchId) {
      this.params = { ean, batchId };
    } else {
      this.params = { id };
    }
    this.trackingFacade
    .get(this.params)
    .subscribe((trackingDetails: any) => {
      this.product = trackingDetails.products[0];
      this.workflowTemplate = trackingDetails.workflowTemplate
      this.productTemplate = this.product.template
      this.companyTemplate = this.product.company.template
      this.selectedTemplate = this.productTemplate? this.productTemplate : (this.workflowTemplate?this.workflowTemplate:this.companyTemplate)
      this.activedTemplate = this.templateService.loadTemplate(this.selectedTemplate)


      console.log(this.activedTemplate)
    })
    }

}
