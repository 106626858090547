import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ProductRatingsPage} from "./product-ratings.page";


const routes: Routes = [

    {
        path: '',
        component: ProductRatingsPage,

    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class ProductRatingsRoutingModule {}
