import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IonSlides, IonicSlides } from '@ionic/angular';

@Component({
  selector: 'app-product-related',
  templateUrl: './product-related.component.html',
  styleUrls: ['./product-related.component.scss'],
})
export class ProductRelatedComponent implements OnInit {

  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;

  private startX: number;
  private scrollLeft: number;
  swiperModules = [IonicSlides];
  @ViewChild(IonSlides) slides: IonSlides;
  
  public images: any = ['slide 1', 'slide 2', 'slide 3', 'slide 4', 'slide 5'];
  constructor() { }

  ngOnInit() {}

  handleTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].pageX - this.scrollContainer.nativeElement.offsetLeft;
    this.scrollLeft = this.scrollContainer.nativeElement.scrollLeft;
  }

  handleTouchMove(event: TouchEvent) {
    if (!this.startX) {
      return;
    }

    const x = event.touches[0].pageX - this.scrollContainer.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 1; // Puedes ajustar el factor según tu preferencia
    this.scrollContainer.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  handleTouchEnd() {
    this.startX = null;
  }

}
