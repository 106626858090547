import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { DomainConfigService } from "./services/domain-config.service";
import { PortesaRoutingModule } from "./templates/portesa/portesa-routing.module";

const routes: Routes = [
  {
    path: "",
    redirectTo: "main",
    pathMatch: "full",
  },
  {
    path: "main",
    data: { showTitle: false },
    loadChildren: () => getMainRoutes(),
  },
  {
    path: "search",
    loadChildren: () => getSearchRoutes(),
  },

  {
    path: "products",
    loadChildren: () => getProductsRoutes(),
  },

  {
    path: "folder/:id",
    loadChildren: () => getMainRoutes(),
  },
  {
    path: "wallet",
    loadChildren: () => getWalletRoutes(),
  },
  {
    path: "projects",
    loadChildren: () => getProjectsRoutes(),
  },
  {
    path: "config",
    loadChildren: () => getConfigRoutes(),
  },

  {
    path: "product-ratings",
    loadChildren: () => getProductRatingsRoutes(),
  },
  {
    path:"tracking-map/:id",
    loadChildren: () => getTrackingMapRoute()
      
  }
];

export function getTrackingMapRoute(){
 return import(`./modules/tracking-map/tracking-map.module`).then((m) => m.TrackingMapPageModule)

}


export function getProductsRoutes() {

  return import(`./modules/products/products.module`).then(
    (m) => m.ProductsModule
  );
}
export function getMainRoutes() {
  switch (DomainConfigService.getSubDomain()) {
    case "portesa":
      return import(`./templates/stelvio/pages/main/main.module`).then(
        (m) => m.MainModule
      );
      break;
    case "dimitra":
      return import(`./templates/stelvio/pages/main/main.module`).then(
        (m) => m.MainModule
      );
      break;
    case "stelvio":
      return import(`./templates/stelvio/pages/main/main.module`).then(
        (m) => m.MainModule
      );
      break;
    default:
      return import(`./templates/stelvio/pages/main/main.module`).then(
        (m) => m.MainModule
      );
  }
}
export function getSearchRoutes() {
  switch (DomainConfigService.getSubDomain()) {
    case "portesa":
      return import(`./templates/stelvio/pages/search/search.module`).then(
        (m) => m.SearchModule
      );
      break;
    case "dimitra":
      return import(`./templates/stelvio/pages/search/search.module`).then(
        (m) => m.SearchModule
      );
      break;
    case "stelvio":
      return import(`./templates/stelvio/pages/search/search.module`).then(
        (m) => m.SearchModule
      );
      break;
    default:
      return import(`./templates/stelvio/pages/search/search.module`).then(
        (m) => m.SearchModule
      );
  }
}
export function getProjectsRoutes() {
  switch (DomainConfigService.getSubDomain()) {
    case "portesa":
      return import(`./templates/stelvio/pages/projects/projects.module`).then(
        (m) => m.ProjectsPageModule
      );
      break;
    case "dimitra":
      return import(`./templates/stelvio/pages/projects/projects.module`).then(
        (m) => m.ProjectsPageModule
      );
      break;
    case "stelvio":
      return import(`./templates/stelvio/pages/projects/projects.module`).then(
        (m) => m.ProjectsPageModule
      );
      break;
    default:
      return import(`./templates/stelvio/pages/projects/projects.module`).then(
        (m) => m.ProjectsPageModule
      );
  }
}
export function getWalletRoutes() {
  switch (DomainConfigService.getSubDomain()) {
    case "portesa":
      return import(`./templates/stelvio/pages/wallet/wallet.module`).then(
        (m) => m.WalletPageModule
      );
      break;
    case "dimitra":
      return import(`./templates/stelvio/pages/wallet/wallet.module`).then(
        (m) => m.WalletPageModule
      );
      break;
    case "stelvio":
      return import(`./templates/stelvio/pages/wallet/wallet.module`).then(
        (m) => m.WalletPageModule
      );
      break;
    default:
      return import(`./templates/stelvio/pages/wallet/wallet.module`).then(
        (m) => m.WalletPageModule
      );
  }
}
export function getConfigRoutes() {
  switch (DomainConfigService.getSubDomain()) {
    case "portesa":
      return import(`./templates/stelvio/pages/config/config.module`).then(
        (m) => m.ConfigPageModule
      );
      break;
    case "dimitra":
      return import(`./templates/stelvio/pages/config/config.module`).then(
        (m) => m.ConfigPageModule
      );
      break;
    case "stelvio":
      return import(`./templates/stelvio/pages/config/config.module`).then(
        (m) => m.ConfigPageModule
      );
      break;
    default:
      return import(`./templates/stelvio/pages/config/config.module`).then(
        (m) => m.ConfigPageModule
      );
  }
}
export function getProductRatingsRoutes() {
  const domain = DomainConfigService.getSubDomain(); // domain -> portesa, dimitra, stelvio
  domain;
  return import(`./templates/${domain}/pages/products/products.module`).then(
    (m) => m.ProductsModule
  );
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
