import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-newsletter',
  templateUrl: './product-newsletter.component.html',
  styleUrls: ['./product-newsletter.component.scss'],
})
export class ProductNewsletterComponent implements OnInit {

  @Input("productBanner") productBanner:any;
  constructor() { }

  ngOnInit() {}

}
