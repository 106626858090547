import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-product-history',
  templateUrl: './product-history.component.html',
  styleUrls: ['./product-history.component.scss'],
})
export class ProductHistoryComponent implements OnInit {
  @Input('steps') steps: any;
  @Input('product') product: any;

  constructor() { }

  ngOnInit() {}
  showDetails(item:any){

  }
}
