import {Injectable} from "@angular/core";
import {TrackingRequest} from "../requests/tracking.request";
import {TrackingSearchFiltersModel} from "../../models/batch-tracking/TrackingSearchFiltersModel";
import {Observable} from "rxjs";

@Injectable()
export class TrackingFacade {
    constructor(private request: TrackingRequest) {
    }

    query(filters?: TrackingSearchFiltersModel): Observable<any> {
        // if(!!filters){
        //     const keys = Object.keys(filters);
        //     keys.map( (key: string) => {
        //         // @ts-ignore
        //         if(filters[key] === undefined || filters[key] === "") {
        //             // @ts-ignore
        //             delete filters[key];
        //         }
        //     });
        // }
        return this.request.query(filters);
    }

    get(params: any) {
        if(params.batchId && params.ean) {
            return this.request.getByQr(params.ean,params.batchId);
        }
        return this.request.getById(params.id);
    }

    getImage(id: string) {
        return this.request.getImage(id);
    }

    getDocument(id: string) {
        return this.request.getDocument(id);
    }

    download(url: string) {
        return this.request.download(url)
    }
}
