import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-ratings',
  templateUrl: './product-ratings.component.html',
  styleUrls: ['./product-ratings.component.scss'],
})
export class ProductRatingsComponent implements OnInit {

  productId:number;
  constructor(private router:Router,private activeRoute:ActivatedRoute) { }

  ngOnInit() {
    this.productId = this.activeRoute.snapshot.params['id'];

  }


  showAllRatings(){

    this.router.navigateByUrl('products/ratings/'+this.productId)
  }
}
