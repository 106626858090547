import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { ProductRatingsPage } from "./product-ratings.page";
import { ProductRatingsRoutingModule } from "./product-ratings-routing.module";
import { IonicModule } from "@ionic/angular";
import { CommonStelvioModule } from "../../common/common.module";

import { ProductsModulePortesa } from "src/app/templates/portesa/pages/products/products.module";
import { ProductsModuleDimitra } from "src/app/templates/dimitra/pages/products/products.module";
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CommonStelvioModule,
    ProductRatingsRoutingModule,
    ProductsModulePortesa,
    ProductsModuleDimitra
   
    ],
  declarations: [
    ProductRatingsPage,

    ],

})
export class ProductRatingsModule {}
