import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, Marker,Polyline } from "@capacitor/google-maps";
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-product-tracking-map',
  templateUrl: './product-tracking-map.component.html',
  styleUrls: ['./product-tracking-map.component.scss'],
})
export class ProductTrackingMapComponent implements OnInit {
  @Input("steps") steps: any;
  @Input("workflowId") workflowId: any;
  map: GoogleMap;
  @ViewChild("trackingMap") trackingMap: ElementRef<HTMLElement>
  mapZoom:number = 12;
  markers:Array<any> = [];
  constructor(private navController: NavController) { }
  polyline:  Polyline;
  polylineOptions: any = {strokeColor: '#000000', strokeOpacity: 1, strokeWeight: 5};
  ngOnInit() {
    setTimeout(()=>{
      this.initTrackingMap()
    },500)
  }


  async initTrackingMap(){
    await this.steps.map((step:any)=>{
      const marker = this.getMarker(step);
      this.markers.push(marker) 
    })

    this.map = await GoogleMap.create({
      id: "tracking-map",
      element: this.trackingMap.nativeElement,
      apiKey: environment.apiKey,
      config: {
        center: this.getCenterByMarkers(),
        zoom: this.mapZoom,
        disableDefaultUI: true, 
        styles: [
          {
            featureType: "all",
            elementType: "all",
            stylers: [
              {
                saturation: -100, 
              },
              {
                lightness: 0, 
              },
            ],
          },
        ],
      },
    });
    
    this.markers.map((marker:any)=>{
      this.map.addMarker(marker)
    })
    
    this.addPolyLines()

  }

  addPolyLines(){
    const path = this.markers.map((coord: any) => {
      console.log(coord)
      return ({lat: coord.coordinate.lat, lng: coord.coordinate.lng})
    });
    const lines: Polyline[] = [
     {
      path: path,
      ...this.polylineOptions
     }
    ]

    this.map.addPolylines(lines)

  }
  getMarker(step): Marker {
    const lati = parseFloat(step.facility.latitude);
    const longi = parseFloat(step.facility.longitude);

    return {
        coordinate: {
            lat: lati,
            lng: longi
        },
        iconUrl:'/assets/img/portesa/map-marker2.png',
        iconAnchor: new google.maps.Point(30, 55),
        iconOrigin: new google.maps.Point(0, 0),
        iconSize: {
          width: 60,
          height: 60,
        },
        title: step.facility.name,
        snippet: step.facility.address
    }
}
 getCenterByMarkers() {

        const minLat = Math.min(...this.markers.map((coord: any) => coord.coordinate.lat));
        const maxLat = Math.max(...this.markers.map((coord: any) => coord.coordinate.lat));
        const minLng = Math.min(...this.markers.map((coord: any) => coord.coordinate.lng));
        const maxLng = Math.max(...this.markers.map((coord: any) => coord.coordinate.lng));

        const centerLat = (minLat + maxLat) / 2;
        const centerLng = (minLng + maxLng) / 2;

        const latDistance = Math.abs(maxLat - minLat);
        const lngDistance = Math.abs(maxLng - minLng);

        const zoomLevel = this.calculateZoomLevel(latDistance, lngDistance);
        const center = {lat: centerLat, lng: centerLng};
        this.mapZoom = zoomLevel;
        return center;
    }

    calculateZoomLevel(latDistance: number, lngDistance: number): number {
      
        const maxDistance = Math.max(latDistance, lngDistance);
        const worldWidth = 256; 
        const zoom = Math.floor(Math.log2(worldWidth / maxDistance));
        return zoom;
    }


    showTrackingMapPage(){
      //this.navController.navigateForward("/tracking-map/" + this.workflowId)
    }
}
