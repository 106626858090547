import {Component, Input, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
    selector: 'app-portesa-product-template',
    templateUrl: './portesa-product-template.component.html',
    styleUrls: ['./portesa-product-template.component.scss'],
})
export class PortesaProductTemplateComponent implements OnInit {
    //TODO:
    @Input('steps') steps: any;
    @Input('product') product: any;
    @Input('user') user: any;
    @Input('imagePath') imagePath: any;
    @Input('workflowId') workflowId: any;
    @Input('workflowName') workflowName: any;
    @Input('workflowDescription') workflowDescription: any;
    @Input('productData') productData: any;



    constructor(private http: HttpClient) {
       
    }

    ngOnInit() {
        // this.http.get('/assets/productData.json').subscribe(data => {
        //     this.productData = data;
        //   });
    }



}

